import React from "react"
import Layout from "hoc/Layout"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Header from "containers/Header/Header"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import Font18 from "components/Fonts/Font18"
import { Grid } from "@mui/material"
import RecrutationSteps from "containers/RecrutationsSteps/RecrutationSteps"
import FormSection from "containers/Homepage/FormSection/FormSection"

const ForNannyRecrutation = () => {
  const breadcrumbsData = [
    {
      title: "Strona Główna",
      link: "/",
    },
    {
      title: "Dla Opiekunki",
      link: "/dla-opiekunki/etapy-rekrutacji-opiekunki-osob-starszych",
    },
    "Etapy rekrutacji Opiekunki Osób Starszych",
  ]

  const { recruitmentMain } = useStaticQuery(
    graphql`
      query {
        recruitmentMain: file(name: { eq: "recruitment-main" }) {
          id
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "transparent"
              quality: 95
              placeholder: NONE
            )
          }
        }
      }
    `
  )

  const mainImage = getImage(recruitmentMain)

  return (
    <Layout
      breadcrumbsData={breadcrumbsData}
      seoTitle="Etapy rekrutacji Opiekunki Osób Starszych"
    >
      <Header
        title={"Rekrutacja Opiekunki Osób Starszych"}
        titleDescription={"Na rekrutacji znamy się najlepiej"}
        mainImage={mainImage}
        mainImageAlt={"Rekrutacja w Medirze"}
      />
      <Hr mb={4} mt={6} />
      <Grid container>
        <Grid item xs={12} sm={10} md={8}>
          <Font42 mb={4}>
            Jak przebiega rekrutacja Opiekunki Osób Starszych w Medirze?
          </Font42>
          <Font18 textAlign="justify">
            W Medirze dokładamy wszelkiej staranności, aby proces rekrutacji
            przez jaki przechodzi każda{" "}
            <strong>Opiekunka Osób Starszych</strong> był przejrzysty i
            komfortowy. Niezależnie czy jesteś{" "}
            <strong>Opiekunką Osób Starszych</strong> w Niemczech czy w Polsce
            zrobimy wszystko, aby proces zatrudnienia w naszej firmie był dla
            Ciebie jak najbardziej przyjazny. Naszym zadaniem jest stworzyć
            optymalne warunki do miłego i przyjemnego procesu zatrudnienia. Nasi
            koordynatorzy stale podnoszą swoje kwalifikacje, aby obsługiwać
            wszystkich kandydatów jeszcze lepiej.
          </Font18>
          <Font18 textAlign="justify">
            W Medirze - liczy się człowiek. To ludzie są dla nas najważniejsi.
            Rozpoczynając proces rekrutacji na{" "}
            <strong>Opiekunkę Osób Starszych</strong> w Medirze możesz mieć
            pewność, że rozpoczynasz pracę w organizacji, która będzie Ci
            służyć.
          </Font18>
        </Grid>
      </Grid>
      <Hr mb={8} mt={6} />
      <Font42 mb={8} textAlign="center">
        Etapy rekrutacji Opiekunki Osób Starszych
      </Font42>
      <RecrutationSteps />
      <FormSection noBottom />
    </Layout>
  )
}

export default ForNannyRecrutation
